* {
    text-decoration: none;
    list-style: none;
}
.site-container {
    display: flex;
    flex-direction: column;
    opacity: 0;
}

.nav-item {
    margin: 0px 5px 0px 5px;
    position: relative;
}

.fab-container {
    position: fixed;
    bottom: 1em;
    right: 2em;
    z-index: 100;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.fab-container button {
    margin-bottom: 10px;
}

.fab-container > div {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 5px;
}

.nav-text:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--current-text-color);
    transition:
        width 0.3s ease 0s,
        left 0.3s ease 0s;
    width: 0;
}
.nav-text:hover:after {
    width: 100%;
    left: 0;
}

.content {
    height: 200vh;
}

.site-header-title {
    font-size: 40px;
}
.liveSiteNav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    padding-right: 30px;
    font-weight: bolder;
}
.iconItem {
    margin-right: 0.2em;
    cursor: pointer;
}
.navMenuFull {
    margin: 10px;
}

.navMenuFull:hover {
    text-decoration: underline;
}
.headerLogo {
    width: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.contact-image {
    max-width: 100%;
    float: left;
    height: auto;
    width: 150px;
    margin-right: 1em;
    border-radius: 100%;
    display: flex;
}

.default-avatar {
    max-width: 100%;
    float: left;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    margin-right: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4em; /* adjust the size as needed */
    font-weight: 900;
}

.material-icons {
    display: inline-flex;
}

.material-icons-Contact {
    display: inline-flex;
}

.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    word-wrap: break-word;
    padding: 10px;
    align-items: center;
    margin-right: 2em;
    height: 90%;
}

.contact-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
}
.contact-header {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
}
.menuContent {
    overflow: hidden !important;
}
.contact-name {
    font-weight: 800;
    margin-top: 4px;
    margin-bottom: 0;
}
.title {
    font-weight: 600;
    text-transform: none;
}
.iconItem {
    display: inline-flex;
    vertical-align: middle;
    width: 2em;
    height: 2em;
    font-size: initial;
}
.powered-by-footer {
    padding: 25px;
}
.contact-footer {
    padding: 25px;
}
.contact-footer > * {
    flex: 1 100%;
}
.content-container h1 {
    font-weight: 800;
    font-size: 2rem;
    margin-bottom: 10px;
    position: relative;
}

.separator {
    display: block;
    width: 5px;
    height: 25px;
    padding-bottom: 10px;
    margin-right: 10px;
}

.separator-2 {
    display: block;
    width: 94px;
    height: 0px;
    padding-bottom: 3px;
    margin-left: 10px;
}

.contact-header {
    margin: 30px 0px 30px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    & h1 {
        margin: 0px;
    }
}

.end-footer-about {
    width: 100%;
    margin: 0 auto;
    display: flex;

    .footer-distributed {
        box-sizing: border-box;
        width: 100%;
        text-align: left;
        margin: 55px 50px 0px 50px;
    }

    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        display: inline-block;
        vertical-align: top;
    }

    /* Footer left */

    .footer-distributed .footer-left {
        width: 40%;
    }

    .blumepress-powered {
        margin-top: 10px;
        & p {
            font-size: small;
            display: flex;
            align-items: center;
        }
    }

    /* The company logo */

    .footer-distributed h3 {
        margin: 0;
    }
    /* Footer links */

    .footer-distributed .footer-links {
        margin: 20px 0 12px;
        font-size: 1rem;
        padding: 0;
    }

    .footer-distributed .footer-links span {
        display: inline-block;
        line-height: 1.8;
        font-weight: 400;
        text-decoration: none;
        color: inherit;
    }

    .footer-distributed .footer-company-name {
        font-weight: normal;
        margin: 0;
    }

    /* Footer Center */

    .footer-distributed .footer-center {
        width: 35%;
    }

    .footer-distributed .footer-center i {
        background-color: var(--secondary-footer-color);
        width: 38px;
        height: 38px;

        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        margin: 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-distributed .footer-center div {
        display: flex;
        align-items: center;
        font-size: medium;
    }

    .footer-distributed .footer-center i.fa-envelope {
        line-height: 38px;
    }

    .footer-distributed .footer-center p {
        display: inline-block;
        font-weight: 400;
        vertical-align: middle;
        margin: 0;
    }

    .footer-distributed .footer-center p span {
        display: block;
        font-weight: normal;
        font-size: 14px;
        line-height: 2;
    }

    .footer-distributed .footer-links a:before {
        content: '|';
        font-weight: 300;
        left: 0;
        color: var(--secondary-footer-color);
        display: inline-block;
        padding-right: 5px;
    }

    .footer-distributed .footer-links .link-1:before {
        content: none;
    }

    /* Footer Right */

    .footer-distributed .footer-right {
        width: 20%;
    }

    .footer-distributed .footer-company-about {
        line-height: 20px;
        font-weight: normal;
        font-size: 13px;
        margin: 0;
    }

    .footer-distributed .footer-company-about span {
        display: block;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 20px;
    }

    .footer-distributed .footer-icons {
        margin-top: 25px;
    }

    .footer-distributed .footer-icons span {
        display: inline-block;
        width: 35px;
        height: 35px;
        cursor: pointer;
        border-radius: 2px;

        text-align: center;
        line-height: 35px;

        margin-right: 3px;
        margin-bottom: 5px;
    }

    /* If you don't want the footer to be responsive, remove these media queries */

    @media (max-width: 880px) {
        .footer-distributed .footer-left,
        .footer-distributed .footer-center,
        .footer-distributed .footer-right {
            display: block;
            width: 100%;
            margin-bottom: 40px;
        }

        .footer-distributed .footer-center i {
            margin-left: 0;
        }
    }
}
.site-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 10vh;
    min-height: 110px;
    transition:
        top 0.3s ease,
        height 0.3s ease; /* Smooth transition for top and height */
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    height: 5vh;
    min-height: 50px;
}

.un-sticky {
    position: fixed;
    top: -150px;
    width: 100%;
    transition: top 0.3s ease; /* Smooth transition for top */
}

.container-input {
    position: relative;
}

.search-bar-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: rgb(34, 34, 34);
}

.search-input {
    width: 200px;
    max-width: 100%;
    padding: 10px 0px 10px 10px;
    border-radius: 9999px;
    border: solid 1px #333;
    transition: all 0.2s ease-in-out;
    outline: none;
    opacity: 0.8;
}

.search-input:focus {
    opacity: 1;
    width: 400px;
    max-width: 100%;
}

.end-footer-contact {
    width: 100%;
    padding-top: 1em;
    padding-left: 15px;
    margin: 0 auto;
    max-width: 1140px;
    margin-bottom: 1em;
}
.width-50 {
    width: 90%;
    margin: 8px;
}
.width-50 {
    width: calc(50% - 16px);
}

.loaderMain {
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    width: 100%;
    height: 100vh;
}

.searchCard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 16px 0px 16px;
    min-width: 360px;
}

.searchCardImage {
    background-size: contain;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    margin-right: 1.5em;
    padding: 10px;
    background-position: center;
    background-color: whitesmoke;
    border-radius: 10px;
}

.mat-mdc-option {
    padding: 0 !important;
}

.searchCardText {
    width: 80%;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.searchCardTitle {
    padding-top: 0.2em;
    font-weight: 700;
    line-height: initial;
}

.line-limit-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.line-limit-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.searchCardIngress {
    font-size: small;
    padding-bottom: 0.2em;
    line-height: initial;
    word-wrap: break-word;
}

@media (max-width: 1024px) {
    mat-toolbar {
        border-radius: 0;
    }
}
@media screen and (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
@media screen and (max-width: 900px) {
    .container {
        max-width: 90%;
    }
    .contact-image {
        width: 100px;
    }
    .default-avatar {
        height: 100px;
        width: 100px;
    }
    .powered-by-footer {
        height: auto;
    }
}
@media screen and (max-width: 600px) {
    .container {
        max-width: 90%;
    }
    .powered-by-footer {
        height: auto;
    }
    .blumepress-powered {
        margin-top: 10px;

        & p {
            font-size: small;
            display: flex;
            align-items: center;
        }
    }

    .end-footer-about {
        width: 100%;
        margin: 0 auto;
        display: flex;

        .footer-distributed {
            box-sizing: border-box;
            width: 100%;
            text-align: left;
            margin: 0px 0px 0px 0px;
        }
    }
    .contact-list {
        justify-content: center;
        .list {
            justify-content: center;
            flex-flow: column nowrap;

            .contact-image {
                margin-bottom: 1em;
            }
            .default-avatar {
                height: 100px;
                width: 100px;
                margin-bottom: 1em;
            }
        }
    }
}

.lds-roller,
.lds-roller div,
.lds-roller div:after {
    box-sizing: border-box;
}
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 7.2px;
    height: 7.2px;
    border-radius: 50%;
    background: currentColor;
    margin: -3.6px 0 0 -3.6px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 62.62742px;
    left: 62.62742px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 67.71281px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 70.90963px;
    left: 48.28221px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 70.90963px;
    left: 31.71779px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 67.71281px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 62.62742px;
    left: 17.37258px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12.28719px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
