.mainstory {
    cursor: pointer;
    margin: 0 auto;
    padding-top: 30px;
    padding: 0;
    display: block;
}

.mainstory:hover {
    opacity: 80%;
}

.separator {
    display: block;
    width: 5px;
    height: 25px;
    margin: 0px 8px 0px 0px;
}

.separator-2 {
    display: block;
    width: 94px;
    height: 0px;
    padding-bottom: 3px;
    margin-left: 10px;
}

.line-limit {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

// Used in main Story
.panel__body_without_image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90%;
}

.featureImg {
    height: auto;
    min-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    vertical-align: middle;
    width: 100%;
}

.panel {
    background-color: #fff;
    border-radius: 1.3rem;
    -webkit-box-shadow:
        0 6px 10px rgba(49, 73, 164, 0.08),
        0 0 3px rgba(49, 73, 164, 0.1),
        0 0 2px rgba(49, 73, 164, 0.14);
    box-shadow:
        0 6px 10px rgba(49, 73, 164, 0.08),
        0 0 3px rgba(49, 73, 164, 0.1),
        0 0 2px rgba(49, 73, 164, 0.14);
    display: block;
    height: 100%;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.panel__content {
    background-color: #fff;
    color: #444;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: 400px;
    position: relative;
    cursor: pointer;
}

.panel__content .panel__media {
    height: 100%;
    width: 100%;
}

.panel__content .panel__media img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.panel__content .panel__body {
    height: 100%;
    margin: 1.5rem;
    overflow: hidden;
}

.panel__content .panel__body .panel__title {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    margin-top: 1rem;
    overflow: hidden;
}

.panel__content .panel__body .panel__date {
    color: #999;
    display: inline-block;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 12px;
}

.panel__content .panel__body .panel__text {
    clear: both;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.panel__content .panel__body .panel__text a {
    text-decoration: underline;
}

@media (min-width: 992px) {
    .panel__content.main-story .panel__media {
        -ms-flex-preferred-size: calc(50.67% - 32px / 3);
        flex-basis: calc(50.67% - 32px / 3);
    }
}

@media screen and (min-width: 576px) {
    .panel__content.main-story {
        -ms-flex-direction: row;
        flex-direction: row;
        max-height: 432px;
    }

    .panel__content.main-story .panel__media {
        -ms-flex-preferred-size: calc(50% - 32px / 2);
        flex-basis: calc(50% - 32px / 2);
        -ms-flex-negative: 0;
        flex-shrink: 0;
        height: auto;
        max-height: 100%;
    }

    .panel__content.main-story .panel__body {
        position: relative;
    }

    .panel__content.main-story .panel__body::after {
        top: calc(400px - 64px);
    }
}

@media screen and (min-width: 768px) {
    .panel__content .panel__body {
        margin: 1.5rem;
    }
}

@media (max-width: 576px) {
    .panel__content.main-story {
        flex-direction: row;
    }

    .panel__content.main-story .panel__media {
        -ms-flex-preferred-size: calc(50% - 32px / 2);
        flex-basis: calc(50% - 32px / 2);
        -ms-flex-negative: 0;
        flex-shrink: 0;
        height: auto;
        max-height: 100%;
    }
}

.lSSlideOuter .lSPager.lSpg > li a {
    background-color: lightgray !important;
}

.lSSlideOuter .lSPager.lSpg > li:hover a,
.lSSlideOuter .lSPager.lSpg > li.active a {
    background-color: grey !important;
}
