<mat-sidenav-container fullscreen>
    <mat-sidenav
        mode="side"
        (mouseover)="openNavigationMenu()"
        (mouseleave)="closeNavigationMenu()"
        opened
        [ngStyle]="{ width: navigationMenuWidth + 'px' }"
    >
        <div class="navigation-list">
            <div
                *ngIf="!isNavigationMenuOpen"
                class="navigation-item navigation-item--main navigation-item--main-icon"
            >
                <mat-icon>keyboard_double_arrow_right</mat-icon>
            </div>

            <div
                *ngIf="isNavigationMenuOpen"
                class="navigation-item navigation-item--main navigation-item--main-opened"
            >
                <div class="blumepress-header">
                    <!-- <mat-icon svgIcon="logo-v"></mat-icon> -->
                    <img
                        src="./../../../assets/icons/logo-v.svg"
                        height="40px"
                    />
                    <!-- <span class="blumepress-header--text">Blumepress</span> -->
                </div>
            </div>
            <div class="navigation-items">
                <div
                    class="navigation-items--navigation-item"
                    *ngFor="let navigationItem of filteredNavigationItems"
                >
                    <div class="navigation-items--icon-container">
                        <a
                            [routerLink]="navigationItem.routerLink"
                            routerLinkActive="navigation-item--active"
                            [class.disabled]="navigationItem.isDisabled"
                        >
                            <mat-icon
                                class="navigation-items--icon"
                                [svgIcon]="navigationItem.svgIcon"
                            ></mat-icon>
                            <div
                                *ngIf="isNavigationMenuOpen"
                                class="navigation-items--icon-text"
                            >
                                {{ navigationItem.title | titlecase }}
                            </div>
                        </a>
                        <mat-icon
                            *ngIf="
                                isNavigationMenuOpen &&
                                navigationItem.children &&
                                !navigationItem.viewExpanded
                            "
                            class="navigation-items--icon navigation-items--navigation-item__expand-icon"
                            (click)="navigationItem.viewExpanded = true"
                        >
                            expand_more
                        </mat-icon>
                        <mat-icon
                            *ngIf="
                                isNavigationMenuOpen &&
                                navigationItem.children &&
                                navigationItem.viewExpanded
                            "
                            class="navigation-items--icon navigation-items--navigation-item__expand-icon"
                            (click)="navigationItem.viewExpanded = false"
                        >
                            expand_less
                        </mat-icon>
                    </div>
                    <div
                        class="navigation-items--navigation-child-container"
                        *ngIf="
                            isNavigationMenuOpen &&
                            navigationItem.children &&
                            navigationItem.viewExpanded
                        "
                    >
                        <div
                            class="navigation-items--navigation-child-item"
                            *ngFor="
                                let childNavigationItem of navigationItem.children
                            "
                        >
                            <a
                                [routerLink]="childNavigationItem.routerLink"
                                routerLinkActive="navigation-item--active"
                                [class.disabled]="
                                    childNavigationItem.isDisabled
                                "
                            >
                                {{ childNavigationItem.title | titlecase }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-spacer"></div>
            <div *ngIf="isNavigationMenuOpen" class="navigation-footer">
                <div class="navigation-footer--main">
                    Blumepress &copy; {{ currentYear }}
                </div>
                <div class="navigation-footer--sub">Copyright • Privacy</div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content
        [ngStyle]="{ 'margin-left.px': sidenavContentLeftMargin }"
    >
        <mat-toolbar class="main-header">
            <div class="main-header--heading">
                <mat-icon
                    class="main-header--heading-icon"
                    svgIcon="blumepress"
                ></mat-icon>
                <span class="main-header--heading-text">
                    {{ currentRoute | titlecase }}
                </span>
            </div>

            <div class="flex-spacer"></div>
            <div style="margin-right: 10px">
                <button
                    mat-mini-fab
                    color="primary"
                    aria-label="Live Pressroom Site"
                    matTooltip="Open live pressroom site"
                    style="cursor: pointer"
                    (click)="goToLiveSite()"
                >
                    <mat-icon color="accent">open_in_new</mat-icon>
                </button>
            </div>

            <div style="margin-right: 10px">
                <div class="account-box" [matMenuTriggerFor]="accountMenuDiv">
                    <mat-icon
                        aria-hidden="false"
                        color="primary"
                        aria-label="home icon"
                        style="margin-right: 8px"
                    >
                        domain
                    </mat-icon>
                    <span>{{ accountName }}</span>
                </div>
                <mat-menu #accountMenuDiv="matMenu" class="menu-content">
                    <div class="menu-container">
                        <ng-container *ngFor="let account of accountMenu">
                            <button
                                *ngIf="account.pressrooms.length > 0"
                                mat-menu-item
                                [matMenuTriggerFor]="pressroomMenu"
                            >
                                {{ account.name }}
                            </button>
                            <button
                                *ngIf="account.pressrooms.length === 0"
                                mat-menu-item
                                (click)="
                                    updatePressroom(
                                        account.id,
                                        'noPressroom',
                                        ''
                                    )
                                "
                            >
                                {{ account.name }}
                            </button>

                            <mat-menu
                                #pressroomMenu="matMenu"
                                class="menu-content"
                            >
                                <button
                                    *ngFor="let pr of account.pressrooms"
                                    mat-menu-item
                                    (click)="
                                        updatePressroom(
                                            account.id,
                                            pr.id,
                                            pr.path
                                        )
                                    "
                                >
                                    <div
                                        *ngIf="
                                            pr.id === pressroomId &&
                                            currentAccount === account.id &&
                                            account.pressrooms.length > 0
                                        "
                                        class="sub-item selected-item"
                                    >
                                        <span>
                                            {{ pr.name | slice: 0 : 40 }}
                                        </span>
                                    </div>
                                    <div
                                        *ngIf="
                                            (pr.id !== pressroomId ||
                                                pressroomId === 'noPressroom' ||
                                                currentAccount !==
                                                    account.id) &&
                                            account.pressrooms.length > 0
                                        "
                                        class="sub-item"
                                    >
                                        <span>
                                            {{ pr.name | slice: 0 : 40 }}
                                        </span>
                                    </div>
                                </button>
                                <button
                                    *ngIf="account.pressrooms.length === 0"
                                    mat-menu-item
                                    (click)="
                                        updatePressroom(
                                            account.id,
                                            'noPressroom',
                                            ''
                                        )
                                    "
                                >
                                    <div
                                        *ngIf="
                                            pressroomId === 'noPressroom' ||
                                            currentAccount === account.id
                                        "
                                        class="sub-item selected-item"
                                    >
                                        <mat-icon color="primary">
                                            done_outline
                                        </mat-icon>
                                        <span>No Pressroom</span>
                                    </div>
                                    <div
                                        *ngIf="
                                            pressroomId !== 'noPressroom' &&
                                            currentAccount !== account.id
                                        "
                                        class="sub-item"
                                    >
                                        <mat-icon></mat-icon>
                                        <span>No Pressroom</span>
                                    </div>
                                </button>
                            </mat-menu>
                        </ng-container>
                    </div>
                </mat-menu>
            </div>

            <div class="user-menu" [matMenuTriggerFor]="userAccount">
                <span class="user-menu--text">{{ fullName | titlecase }}</span>
                <span class="user-menu--icon-container">
                    <mat-icon
                        class="user-menu--icon"
                        svgIcon="avatar"
                    ></mat-icon>
                </span>
            </div>
            <mat-menu #userAccount="matMenu">
                <div class="user-menu-popup">
                    <div class="user-menu-icon">{{ userName | uppercase }}</div>
                    <div class="user-menu-content">
                        <div class="user-menu-text">
                            {{ fullName | titlecase }}
                        </div>
                        <div class="user-menu-text-sub">{{ emailId }}</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="userBadge">
                    <div
                        *ngIf="
                            pressroomId !== '' && pressroomId !== 'noPressroom'
                        "
                        class="badgeContent"
                    >
                        <div
                            class="fullName"
                            style="display: flex; align-items: center"
                        >
                            <h4 style="margin: 0; padding: 0">
                                {{ 'Email Auditor' | titlecase }}
                            </h4>

                            <mat-icon
                                class="copy-icon"
                                (click)="copyEmail(emailListener)"
                            >
                                content_copy
                            </mat-icon>
                        </div>
                        <div class="email-container">
                            <a
                                class="emailId"
                                href="mailto:{{ emailListener }}"
                            >
                                <p>{{ emailListener }}</p>
                            </a>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            pressroomId === '' || pressroomId === 'noPressroom'
                        "
                        class="badgeContent"
                    >
                        <div class="fullName">
                            <h4 style="margin: 0; padding: 0">
                                {{ 'Email Auditor' | titlecase }}
                            </h4>
                        </div>
                        <div class="email-container">
                            <span class="emailId">
                                There is no pressroom available. Please reload
                                the page.
                            </span>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <button
                    class="user-menu-actions"
                    mat-menu-item
                    (click)="changePassword()"
                >
                    <h4>Change Password</h4>
                </button>
                <button
                    class="user-menu-actions"
                    mat-menu-item
                    (click)="logout()"
                >
                    <h4>Logout</h4>
                </button>
            </mat-menu>
        </mat-toolbar>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
